import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight"
import { FaAngleLeft } from "@react-icons/all-files/fa/FaAngleLeft"

import { Section, Box } from "../../components/Core"
import PostCard from "../../components/PostCard"
import Pagination from "../../components/Pagination"
import { LocalizedLink } from "gatsby-theme-i18n"

function BlogList({ data, pageContext }) {
  const posts = data.edges
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <>
      {/* <!-- Blog section --> */}
      <Section className="position-relative pt-0">
        <Container>
          <Row className="align-items-stretch justify-content-center">
            {posts.map((edge) => (
              <Col lg="4" className="mb-5" key={edge.node.id}>
                <PostCard
                  gatsbyImageData={
                    edge.node.frontmatter.banner
                      ? edge.node.frontmatter.banner.childImageSharp
                          .gatsbyImageData
                      : ""
                  }
                  preTitle={edge.node.frontmatter.date}
                  title={edge.node.frontmatter.title}
                  link={edge.node.fields.slug}
                  readMore
                >
                  {edge.node.excerpt}
                </PostCard>
              </Col>
            ))}
          </Row>
          {numPages > 1 && (
            <Box className="d-flex justify-content-center" mt={4}>
              <Pagination>
                {!isFirst && (
                  <li className="page-item">
                    <LocalizedLink
                      to={`/blog/${prevPage}`}
                      rel="prev"
                      className="page-link"
                    >
                      <FaAngleLeft />
                    </LocalizedLink>
                  </li>
                )}
                {Array.from({ length: numPages }, (_, i) => (
                  <li
                    key={`pagination-number${i + 1}`}
                    className={`page-item ${
                      i + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    <LocalizedLink
                      to={`/blog${i === 0 ? "" : "/" + (i + 1)}`}
                      rel="prev"
                      className="page-link"
                    >
                      {i + 1}
                    </LocalizedLink>
                  </li>
                ))}
                {!isLast && (
                  <li className="page-item">
                    <LocalizedLink
                      to={`/blog/${nextPage}`}
                      rel="next"
                      className="page-link"
                    >
                      <FaAngleRight />
                    </LocalizedLink>
                  </li>
                )}
              </Pagination>
            </Box>
          )}
        </Container>
      </Section>
    </>
  )
}

export default BlogList
