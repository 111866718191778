import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import PageWrapper from "../PageWrapper"
import { Section, Title, Text } from "../Core"

import BlogList from "../../sections/blog/BlogList"
import { graphql } from "gatsby"
import { t, Trans } from "@lingui/macro"
import SEO from "../SEO"

import Newsletter from "../../sections/landing/Newsletter"

const BlogRegular = ({ data, pageContext, location }) => {
  return (
    <>
      <SEO location={location.pathname} title={t`Blog`} />
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title variant="hero">
                  <Trans>AMAI Blog</Trans>
                </Title>
                <Text>
                  <Trans>Data Science, Machine Learning & KI.</Trans>
                </Text>
                <Text>
                  <a href="#newsletter_signup_footer"><Trans>Bleiben Sie mit unserem Newsletter auf dem Laufenden!</Trans></a>
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <BlogList data={data.allMdx} pageContext={pageContext} />
        <Newsletter />
      </PageWrapper>
    </>
  )
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $locale: String!) {
    allMdx(
      filter: {
        fields: { sourceInstanceName: { eq: "posts" }, locale: { eq: $locale } }
        frontmatter: { date: { ne: null }, status: { eq: "published" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            date(formatString: "DD MMM, YYYY")
            banner {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 2
                  placeholder: BLURRED
                )
              }
            }
          }
          excerpt(pruneLength: 180, truncate: true)
        }
      }
    }
  }
`

export default BlogRegular
